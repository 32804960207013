@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'typeface-poppins';
@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

* {
    box-sizing: border-box;
    text-decoration: none;
}

/* nav */
.nav-link {
    font-family: Poppins;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

nav a.active {
    color: #19844A;
}

nav a.active button {
    background: #19844A;
    border: #19844A solid 2px;
}

/* banner */
.banner h2 {
    font-family: Inter;
    font-weight: 800;
}

.banner p {
    font-family: Poppins;
    font-weight: 300;
}

button:hover {
    transition: ease-in-out .5s;
    box-shadow: 0 2px 20px #19844A;
}

/* footer */

.footer {
    font-family: Poppins;
}

.footer h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 3;
}

.footer ul li {
    font-size: 18px;
    font-weight: 400;
    line-height: 2.4;
    color: #ece9e9;
}

.footer address {
    font-size: 16px;
    font-weight: 600;
}

/* home */
.home .service h2 {
    font-family: Montserrat;
    color: #454847;
}

.home .service h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    color: #454847;
}

.home .service p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #45484799;
}

.home .service button {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.home .Latest h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #19844A;
}

.home .homework {
    box-shadow: 0px 0px 8px 0px #4548471A;
}

.home .Latest .head-child {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.home .service-card {
    box-shadow: 0px 0px 8px 0px #DDF4E7B2;
}

.contact .contact_content p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #454847B2;
}

.contact .contact_content h2 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    color: #454847;
}

.contact .contact_content h2 svg {
    margin-top: 5px;
}

.contact input::placeholder {
    color: #454847CC;
}

/* service */
.service h1 {
    font-family: Montserrat;
    font-weight: 700;
    color: #454847;
}

@media only screen and (max-width: 500px) {
    #home .banner {
        height: 100vh;
    }

    .contact h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
    }

    .contact p {
        font-size: 12px;
    }

    .contact .service h2 {
        margin-top: 50px;
    }

    .banner p {
        padding-top: 0;
        padding-bottom: 0;
    }

    .banner h2 {
        margin-top: 70px;
    }

    h3 {
        font-size: 20px !important;
    }

    .home .service p {
        font-size: 13px !important;
    }

    .home .service button {
        font-size: 13px;
        padding: 6px 12px;
    }

    .contact button {
        padding: 6px, 14px, 6px, 14px !important;
    }

    .banner button {
        margin-top: 70px;
    }

    footer h3,
    .footer ul li {
        font-size: 14px !important;
    }

    footer h3 {
        line-height: 1 !important;
    }

    footer address {
        font-size: 12px !important;
    }
}